import {
  CREATE_LORRY_RECEIPT,
  CREATE_LORRY_RECEIPT_COMPLETED,
  CREATE_LORRY_RECEIPT_FAILED,
  FETCH_LORRY_RECEIPT_BY_ID,
  FETCH_LORRY_RECEIPT_BY_ID_COMPLETED,
  FETCH_LORRY_RECEIPT_BY_ID_FAILED,
  FETCH_LORRY_RECEIPT_DATA,
  FETCH_LORRY_RECEIPT_DATA_COMPLETED,
  FETCH_LORRY_RECEIPT_DATA_FAILED,
} from '../../lorryReceipts';

const INITIAL_STATE = {
  isFetching: false,
  delivery_mode: [],
  transfer_mode: [],
  packing_types: [],
  freight_to_be_billed: [],
  seal_border_type: [],
  goods: [],
  trips: {},
  trip_ids: [],
  creatingLR: false,
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_LORRY_RECEIPT_DATA:
    case FETCH_LORRY_RECEIPT_BY_ID:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_LORRY_RECEIPT_DATA_COMPLETED:
    case FETCH_LORRY_RECEIPT_BY_ID_COMPLETED:
      return {
        ...state,
        isFetching: false,
        customer_name: payload.customer_name,
        trips: payload.trips,
        trip_ids: payload.trip_ids,
        delivery_mode: payload.delivery_mode,
        transfer_mode: payload.transfer_mode,
        packing_types: payload.packing_types,
        freight_to_be_billed: payload.freight_to_be_billed,
        seal_border_type: payload.seal_border_type,
        goods: payload.goods,
        branch_data: payload.branch_data,
        branch_ids: payload.branch_ids,
        material_data: payload.material_data,
        material_ids: payload.material_ids,
        delivery_order_booking: payload.delivery_order_booking,
      };
    case FETCH_LORRY_RECEIPT_DATA_FAILED:
    case FETCH_LORRY_RECEIPT_BY_ID_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload?.error,
      };
    case CREATE_LORRY_RECEIPT:
      return {...state, creatingLR: true};
    case CREATE_LORRY_RECEIPT_COMPLETED:
    case CREATE_LORRY_RECEIPT_FAILED:
      return {...state, creatingLR: false};
    default:
      return state;
  }
};
