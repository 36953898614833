import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  resetPage: false,
  searching: '',
  isFetchingOptions: false,
  downloadReportOptionList: [],
  isDownloadingVehicleUtilize: false,
  isFetchingVehicleUtilize: false,
  vehicleUtilizeData: {},
};

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_COMPLETED = 'DOWNLOAD_REPORT_COMPLETED';
export const DOWNLOAD_REPORT_FAILED = 'DOWNLOAD_REPORT_FAILED';

export const DOWNLOAD_REPORT_OPTIONS = 'DOWNLOAD_REPORT_OPTIONS';
export const DOWNLOAD_REPORT_OPTIONS_COMPLETED =
  'DOWNLOAD_REPORT_OPTIONS_COMPLETED';
export const DOWNLOAD_REPORT_OPTIONS_FAILED = 'DOWNLOAD_REPORT_OPTIONS_FAILED';

export const FETCH_VEHICLE_UTILIZATION = 'FETCH_VEHICLE_UTILIZATION';
export const FETCH_VEHICLE_UTILIZATION_COMPLETED =
  'FETCH_VEHICLE_UTILIZATION_COMPLETED';
export const FETCH_VEHICLE_UTILIZATION_FAILED =
  'FETCH_VEHICLE_UTILIZATION_FAILED';

export const DOWNLOAD_VEHICLE_UTILIZATION = 'DOWNLOAD_VEHICLE_UTILIZATION';
export const DOWNLOAD_VEHICLE_UTILIZATION_COMPLETED =
  'DOWNLOAD_VEHICLE_UTILIZATION_COMPLETED';
export const DOWNLOAD_VEHICLE_UTILIZATION_FAILED =
  'DOWNLOAD_VEHICLE_UTILIZATION_FAILED';

export const downloadReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.downloadReportURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadReportOptions = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_REPORT_OPTIONS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.downloadReportOptionsURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_REPORT_OPTIONS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_REPORT_OPTIONS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REPORT_OPTIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//Vehicle Utilization

export const fetchVehicleUtilization = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_UTILIZATION});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.fetchVehicleUtilizationURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_VEHICLE_UTILIZATION_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_UTILIZATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_UTILIZATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadVehicleUtilization = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_VEHICLE_UTILIZATION});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.downloadVehicleUtilizationURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_VEHICLE_UTILIZATION_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_VEHICLE_UTILIZATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_VEHICLE_UTILIZATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case DOWNLOAD_REPORT:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case DOWNLOAD_REPORT_COMPLETED:
      const {email_sent = false} = payload;
      return {
        ...state,
        isFetching: false,
        hasError: false,
        email_sent,
        ...payload,
      };
    case DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        ...payload,
      };

    case DOWNLOAD_REPORT_OPTIONS:
      return {
        ...state,
        isFetchingOptions: true,
      };
    case DOWNLOAD_REPORT_OPTIONS_COMPLETED:
      return {
        ...state,
        isFetchingOptions: false,
        downloadReportOptionList: payload,
      };
    case DOWNLOAD_REPORT_OPTIONS_FAILED:
      return {
        ...state,
        isFetchingOptions: false,
      };
    case FETCH_VEHICLE_UTILIZATION:
      return {...state, isFetchingVehicleUtilize: true};
    case FETCH_VEHICLE_UTILIZATION_COMPLETED:
      return {
        ...state,
        isFetchingVehicleUtilize: false,
        vehicleUtilizeData: payload,
      };
    case FETCH_VEHICLE_UTILIZATION_FAILED:
      return {...state, isFetchingVehicleUtilize: false};

    case DOWNLOAD_VEHICLE_UTILIZATION:
      return {...state, isDownloadingVehicleUtilize: true};
    case DOWNLOAD_VEHICLE_UTILIZATION_COMPLETED:
      return {...state, isDownloadingVehicleUtilize: false};
    case DOWNLOAD_VEHICLE_UTILIZATION_FAILED:
      return {...state, isDownloadingVehicleUtilize: false};
    default:
      return state;
  }
};
