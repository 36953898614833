import {combineReducers} from 'redux';
import allTrips from './allTrips';
import tripAdvance from './tripAdvance';
import requestStatus from './requestStatus';
import lorryReceipts from './lorryReceipts';
import allLorryReceipts from './allLorryReceipts';
import tracking from './tracking';
import initialLoading from './initialLoading';
import allBookings from './allBookings';
import createBooking from './createBooking';
import allRecords from './allRecords';
import verifyVendor from './verifyVendor';
import vendors from './vendors';
import addVendor from './addVendor';
import customers from './customers';
import verifyCustomer from './verifyCustomer';
import addCustomer from './addCustomer';
import parentCustomers from './parentCustomers';
import customerTeamTypes from './customerTeamType';
import vehicles from './vehicles';
import drivers from './drivers';
import routeTat from './routeTat';
import cities from './cities';
import consignors from './consignors';
import distanceMap from './distanceMap';
import allUser from './allUsers';
import editBooking from './editBooking';
import bulkUploadPODRequest from './bulkUploadPOD';
import bulkUpdatePodStatus from './bulkUploadPOD';
import vehicleAverageRunReport from './vehicleAverageRunReport';
import markAttendance from './markAttendance';
import happayProcessingRequest from './happayProcessingRequest';
import downloadReport from './report';
import FnF from './fnf';
import fastTagDieselUpload from './fastTagDieselUpload';
import approveFastTag from './approveFastTag';
import approveDiesel from './approveDiesel';
import maintenanceReport from './maintenanceReport';
import customerTripCount from './customerTripsCount';
import dailyCustomerReport from './dailyCustomerReport';
import customerReport from './customerReport';
import dashboard from './dashboard';
import vendorWiseDistribution from './vendorWiseDistribution';
import zoneWiseDistribution from './zoneWiseDistribution';
import haltDurations from './haltDurations';
import zastNowReport from './zastNowReport';
import contractedVehicle from './contractedVehicle';
import trackingMapView from './trackingMapView';
import tripPerformance from './tripPerformance';
import sendToLoadBoard from './sendToLoadBoard';
import allGeoFences from './allGeoFences';
import trackingDriver from './trackingDriver';
import bookingBids from './bookingBids';
import unverifiedDryruns from './unverifiedDryruns';
import availableVehicles from './availableVehicles';
import availableVehiclesReport from './availableVehiclesReport';
import marketRevenue from './marketRevenue';
import marketRevenueAdded from './marketRevenueAdded';
import vehicleDrawer from './vehicleDrawer';
import tripStepForm from './tripStepForm';
import alertMails from './alertMails';
import driverUploads from './driverUploads';
import uploadFinance from './uploadFinance';
import revenueBaseRate from './revenueBaseRate';
import vendorBaseRate from './vendorBaseRate';
import vehicleStatusSummaryReport from './vehicleStatusSummaryReport';
import rateMatrix from './rateMatrix';
import bulkUpload from './bulkUpload';
import alertMailDashboard from './alertMailDashboard';
import tickets from './tickets';
import createDO from './createDo';
import billingEntity from './billing_entity';
import directPayoutHistory from './finance/index';

export default combineReducers({
  directPayoutHistory,
  zoneWiseDistribution,
  vendorWiseDistribution,
  dailyCustomerReport,
  customerReport,
  customerTripCount,
  maintenanceReport,
  tripAdvance,
  requestStatus,
  FnF,
  allTrips,
  lorryReceipts,
  allLorryReceipts,
  initialLoading,
  tracking,
  allBookings,
  createBooking,
  allRecords,
  verifyVendor,
  vendors,
  addVendor,
  customers,
  verifyCustomer,
  addCustomer,
  parentCustomers,
  customerTeamTypes,
  vehicles,
  drivers,
  routeTat,
  cities,
  consignors,
  distanceMap,
  allUser,
  editBooking,
  bulkUploadPODRequest,
  bulkUpdatePodStatus,
  vehicleAverageRunReport,
  markAttendance,
  happayProcessingRequest,
  downloadReport,
  fastTagDieselUpload,
  approveFastTag,
  approveDiesel,
  haltDurations,
  zastNowReport,
  contractedVehicle,
  trackingMapView,
  tripPerformance,
  allGeoFences,
  trackingDriver,
  sendToLoadBoard,
  unverifiedDryruns,
  availableVehicles,
  bookingBids,
  availableVehiclesReport,
  marketRevenue,
  marketRevenueAdded,
  dashboard,
  vehicleDrawer,
  tripStepForm,
  alertMails,
  driverUploads,
  uploadFinance,
  vehicleStatusSummaryReport,
  rateMatrix,
  bulkUpload,
  revenueBaseRate,
  vendorBaseRate,
  alertMailDashboard,
  tickets,
  createDO,
  billingEntity,
});
