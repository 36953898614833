import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Revenue} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  isDownloading: false,
  filters: {},
  isFetchingBaseRates: false,
  revenueRate: {},
  customers: {},
  revenueRateIds: [],
  currentBaseRateFilterData: null,
  searching: '',
  editRevenueBaseRateLoader: false,
};

const CREATE_REVENUE_BASE_RATE = 'CREATE_REVENUE_BASE_RATE';
const CREATE_REVENUE_BASE_RATE_COMPLETED = 'CREATE_REVENUE_BASE_RATE_COMPLETED';
const CREATE_REVENUE_BASE_RATE_FAILED = 'CREATE_REVENUE_BASE_RATE_FAILED';

const FETCH_REVENUE_BASE_RATES = 'FETCH_REVENUE_BASE_RATES';
const FETCH_REVENUE_BASE_RATES_COMPLETED = 'FETCH_REVENUE_BASE_RATES_COMPLETED';
const FETCH_REVENUE_BASE_RATES_FAILED = 'FETCH_REVENUE_BASE_RATES_FAILED';

export const EDIT_REVENUE_BASE_RATE = 'EDIT_REVENUE_BASE_RATE';
export const EDIT_REVENUE_BASE_RATE_COMPLETED =
  'EDIT_REVENUE_BASE_RATE_COMPLETED';
export const EDIT_REVENUE_BASE_RATE_FAILED = 'EDIT_REVENUE_BASE_RATE_FAILED';

const DOWNLOAD_BASE_RATE_REPORT = 'DOWNLOAD_BASE_RATE_REPORT';
const DOWNLOAD_BASE_RATE_REPORT_COMPLETED =
  'DOWNLOAD_BASE_RATE_REPORT_COMPLETED';
const DOWNLOAD_BASE_RATE_REPORT_FAILED = 'DOWNLOAD_BASE_RATE_REPORT_FAILED';

const RESET_PAGE = 'RESET_PAGE';
const SET_CURRENT_BASE_RATE_FILTER_DATA = 'SET_CURRENT_BASE_RATE_FILTER_DATA';
const FILTER_BASE_RATE_FALSE = 'FILTER_BASE_RATE_FALSE';
const FILTER_BASE_RATE_TRUE = 'FILTER_BASE_RATE_TRUE';

export const createRevenueBaseRate = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_REVENUE_BASE_RATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.createRevenueBaseRateURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'ADD_UPDATE_BASE_RATE', payload: data});
        dispatch({type: CREATE_REVENUE_BASE_RATE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_REVENUE_BASE_RATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_REVENUE_BASE_RATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadBaseRateReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_BASE_RATE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.downloadBaseRateReportURL(),
        data: {...requestData},
      });
      console.log('response --DOWNLOAD_BASE_RATE_REPORT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: DOWNLOAD_BASE_RATE_REPORT_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: DOWNLOAD_BASE_RATE_REPORT_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_BASE_RATE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const baseRateFilterData = (data = '') => (dispatch) => {
  dispatch({
    type: SET_CURRENT_BASE_RATE_FILTER_DATA,
    payload: data,
  });
};

export const filteredData = (searchInput = '') => (dispatch) => {
  console.log('report pod called');
  if (!searchInput) {
    dispatch({
      type: FILTER_BASE_RATE_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_BASE_RATE_TRUE,
    payload: searchInput,
  });
};

export const fetchRevenueBaseRates = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_REVENUE_BASE_RATES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRevenueBaseRatesURL(page, search),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_REVENUE_BASE_RATES_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_REVENUE_BASE_RATES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_REVENUE_BASE_RATES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editRevenueBaseRate = (baseRateId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_REVENUE_BASE_RATE});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Revenue.editRevenueBaseRateURL(baseRateId),
        data: requestData,
      });
      console.log('response --EDIT_REVENUE_BASE_RATE--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: 'EDIT_UPDATE_BASE_RATE', payload: data});
        dispatch({
          type: EDIT_REVENUE_BASE_RATE_COMPLETED,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_REVENUE_BASE_RATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_REVENUE_BASE_RATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case EDIT_REVENUE_BASE_RATE:
      return {
        ...state,
        editRevenueBaseRateLoader: true,
      };
    case EDIT_REVENUE_BASE_RATE_COMPLETED:
      return {
        ...state,
        editRevenueBaseRateLoader: false,
      };
    case EDIT_REVENUE_BASE_RATE_FAILED:
      return {
        ...state,
        editRevenueBaseRateLoader: false,
      };
    case CREATE_REVENUE_BASE_RATE:
      return {...state, isFetching: true};
    case CREATE_REVENUE_BASE_RATE_COMPLETED:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_REVENUE_BASE_RATE_FAILED:
      return {...state, isFetching: false};
    case DOWNLOAD_BASE_RATE_REPORT:
      return {...state, isDownloading: true};
    case DOWNLOAD_BASE_RATE_REPORT_COMPLETED:
      return {
        ...state,
        isDownloading: false,
      };
    case DOWNLOAD_BASE_RATE_REPORT_FAILED:
      return {...state, isDownloading: false};
    case FETCH_REVENUE_BASE_RATES:
      return {
        ...state,
        isFetchingBaseRates: true,
        hasError: false,
        error: '',
      };
    case SET_CURRENT_BASE_RATE_FILTER_DATA: {
      return {
        ...state,
        currentBaseRateFilterData: payload,
      };
    }
    case FETCH_REVENUE_BASE_RATES_COMPLETED:
      return {
        ...state,
        isFetchingBaseRates: false,
        revenueRate: payload?.revenue_rate,
        revenueRateIds: payload?.revenue_rate_ids,
        customers: payload?.customers,
        total: payload?.total,
        filters: payload?.filters,
        hasError: false,
      };
    case FETCH_REVENUE_BASE_RATES_FAILED:
      return {
        ...state,
        isFetchingBaseRates: false,
        hasError: true,
        error: payload.error,
      };
    case FILTER_BASE_RATE_FALSE:
      return {
        ...state,
        searching: '',
      };
    case FILTER_BASE_RATE_TRUE:
      return {
        ...state,
        searching: payload,
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };

    default:
      return state;
  }
};
