import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Revenue} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  isUpdating: false,
  isLoading: false,
  hasError: false,
  error: '',
  customerIds: [],
  customers: {},
  customerTripIds: [],
  customer_trips: {},
  customerCount: null,
};

export const FETCH_MARKET_REVENUE_DETAILS_BY_ID =
  'FETCH_MARKET_REVENUE_DETAILS';
export const FETCH_MARKET_REVENUE_DETAILS_BY_ID_COMPLETED =
  'FETCH_MARKET_REVENUE_DETAILS_COMPLETED';
export const FETCH_MARKET_REVENUE_DETAILS_BY_ID_FAILED =
  'FETCH_MARKET_REVENUE_DETAILS_FAILED';

export const FETCH_MARKET_REVENUE_DETAILS = 'FETCH_MARKET_REVENUE_DETAILS';
export const FETCH_MARKET_REVENUE_DETAILS_COMPLETED =
  'FETCH_MARKET_REVENUE_DETAILS_COMPLETED';
export const FETCH_MARKET_REVENUE_DETAILS_FAILED =
  'FETCH_MARKET_REVENUE_DETAILS_FAILED';

const FETCH_REVENUE_CUSTOMER_TRIPS = 'FETCH_REVENUE_CUSTOMER_TRIPS';
const FETCH_REVENUE_CUSTOMER_TRIPS_COMPLETED =
  'FETCH_REVENUE_CUSTOMER_TRIPS_COMPLETED';
const FETCH_REVENUE_CUSTOMER_TRIPS_FAILED =
  'FETCH_REVENUE_CUSTOMER_TRIPS_FAILED';

const SAVE_REVENUE_CUST_TRIP = 'SAVE_REVENUE_CUST_TRIP';
const SAVE_REVENUE_CUST_TRIP_COMPLETED = 'SAVE_REVENUE_CUST_TRIP_COMPLETED';
const SAVE_REVENUE_CUST_TRIP_FAILED = 'SAVE_REVENUE_CUST_TRIP_FAILED';

const FILTER_REVENUE_DETAILS_TRUE = 'FILTER_REVENUE_DETAILS_TRUE';
const FILTER_REVENUE_DETAILS_FALSE = 'FILTER_REVENUE_DETAILS_FALSE';

export const fetchCustomerDetailsById = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_MARKET_REVENUE_DETAILS_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.fetchCustomerDetailsByIdURL(),
        data: {customer_id: id},
      });
      console.log('response --FETCH_MARKET_REVENUE_DETAILS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_MARKET_REVENUE_DETAILS_BY_ID_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_MARKET_REVENUE_DETAILS_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_MARKET_REVENUE_DETAILS_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchMarketRevenueDetails = (search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_MARKET_REVENUE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchMarketRevenueDetailsURL(search),
      });
      console.log('response --FETCH_MARKET_REVENUE_DETAILS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_MARKET_REVENUE_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_MARKET_REVENUE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_MARKET_REVENUE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchRevenueCustomerTrips = (customerId, filter) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_REVENUE_CUSTOMER_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRevenueCustomerTripsURL(customerId, filter),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_REVENUE_CUSTOMER_TRIPS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_REVENUE_CUSTOMER_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_REVENUE_CUSTOMER_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const saveRevenueCustomerTrip = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_REVENUE_CUST_TRIP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.saveRevenueCustomerTripURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: SAVE_REVENUE_CUST_TRIP_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SAVE_REVENUE_CUST_TRIP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_REVENUE_CUST_TRIP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setUpdatedCustomerTrip = (state, payload = {}) => {
  const {customer_trips} = payload;
  if (customer_trips) {
    const oldRoutes = {...state.customer_trips};
    const newRoutes = {...customer_trips};
    const updatedState = {...oldRoutes, ...newRoutes};
    console.log('merged customer trips', updatedState, oldRoutes, newRoutes);
    return {...state, isLoading: false, customer_trips: updatedState};
  } else return state;
};

export const filteredData = (searchInput = '') => (dispatch) => {
  console.log('filter revenue called');
  if (!searchInput) {
    dispatch({
      type: FILTER_REVENUE_DETAILS_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_REVENUE_DETAILS_TRUE,
    payload: searchInput,
  });
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_REVENUE_CUSTOMER_TRIPS:
    case FETCH_MARKET_REVENUE_DETAILS:
      return {
        ...state,
        isUpdating: true,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case SAVE_REVENUE_CUST_TRIP:
      return {...state, isLoading: true};
    case FETCH_MARKET_REVENUE_DETAILS_COMPLETED:
      return {
        ...state,
        searching: '',
        isUpdating: false,
        isFetching: false,
        hasError: false,
        customerIds: payload?.customer_ids,
        customerCount: payload?.count,
        customers: payload?.customer_data,
      };
    case FETCH_MARKET_REVENUE_DETAILS_BY_ID:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_MARKET_REVENUE_DETAILS_BY_ID_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        customerIds: payload?.customer_ids,
        customerCount: payload?.count,
        customers: payload?.customer_data,
      };
    case FETCH_REVENUE_CUSTOMER_TRIPS_COMPLETED:
      return {
        ...state,
        hasError: true,
        isFetching: false,
        error: payload,
        customerTripIds: payload?.customer_trip_list,
        customer_trips: payload?.customer_trips,
      };
    case SAVE_REVENUE_CUST_TRIP_COMPLETED:
      return setUpdatedCustomerTrip(state, payload);
    case FETCH_REVENUE_CUSTOMER_TRIPS_FAILED:
    case FETCH_MARKET_REVENUE_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',
        error: payload.error,
        customerIds: [],
        customers: {},
        customer_trips: {},
      };
    case SAVE_REVENUE_CUST_TRIP_FAILED:
      return {...state, isLoading: false, error: payload?.error};
    case FILTER_REVENUE_DETAILS_FALSE:
      return {
        ...state,
        searching: '',
      };
    case FILTER_REVENUE_DETAILS_TRUE:
      return {
        ...state,
        searching: payload,
      };
    default:
      return state;
  }
};
