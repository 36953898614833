import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Finance} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  advanceRecordIds: [],
  diesel_records_detail: [],
  advanceTrips: {},
  customerData: {},
  search: '',
  history: [],
  charges: [],
  prevCharges: {},
  employees: [],
  filters: {},
};

export const FETCH_REQUEST_STATUS = 'FETCH_REQUEST_STATUS';
export const FETCH_REQUEST_STATUS_COMPLETED = 'FETCH_REQUEST_STATUS_COMPLETED';
export const FETCH_REQUEST_STATUS_FAILED = 'FETCH_REQUEST_STATUS_FAILED';

export const FETCH_TRIP_ADVANCE_HISTORY = 'FETCH_TRIP_ADVANCE_HISTORY';
export const FETCH_TRIP_ADVANCE_HISTORY_COMPLETED =
  'FETCH_TRIP_ADVANCE_HISTORY_COMPLETED';
export const FETCH_TRIP_ADVANCE_HISTORY_FAILED =
  'FETCH_TRIP_ADVANCE_HISTORY_FAILED';

export const FETCH_TRIP_CHARGES_BY_CODE = 'FETCH_TRIP_CHARGES_BY_CODE';
export const FETCH_TRIP_CHARGES_BY_CODE_COMPLETED =
  'FETCH_TRIP_CHARGES_BY_CODE_COMPLETED';
export const FETCH_TRIP_CHARGES_BY_CODE_FAILED =
  'FETCH_TRIP_CHARGES_BY_CODE_FAILED';

export const FETCH_PREV_TRIP_CHARGES = 'FETCH_PREV_TRIP_CHARGES';
export const FETCH_PREV_TRIP_CHARGES_COMPLETED =
  'FETCH_PREV_TRIP_CHARGES_COMPLETED';
export const FETCH_PREV_TRIP_CHARGES_FAILED = 'FETCH_PREV_TRIP_CHARGES_FAILED';

export const fetchRequestStatus = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_REQUEST_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Finance.fetchRequestStatusURL(search),
        // data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_REQUEST_STATUS_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_REQUEST_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_REQUEST_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripAdvanceHistory = (tripCode) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_ADVANCE_HISTORY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Finance.tripAdvanceHistoryURL(tripCode),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TRIP_ADVANCE_HISTORY_COMPLETED,
          payload: {history: data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_ADVANCE_HISTORY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_ADVANCE_HISTORY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripChargesByCode = (tripCode) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_CHARGES_BY_CODE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Finance.tripChagresByCodeURL(tripCode),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TRIP_CHARGES_BY_CODE_COMPLETED,
          payload: {charges: [data]},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_CHARGES_BY_CODE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_CHARGES_BY_CODE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchPrevTripCharges = (tripCode) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_PREV_TRIP_CHARGES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Finance.fetchPrevTripChargesURL(tripCode),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_PREV_TRIP_CHARGES_COMPLETED,
          payload: {prevCharges: data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_PREV_TRIP_CHARGES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_CHARGES_BY_CODE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_REQUEST_STATUS:
      return {...state, isFetching: true};
    case FETCH_REQUEST_STATUS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        advanceRecordIds: payload.advance_record_ids,
        advanceTrips: payload.advance_trips,
        customerData: payload.customer_data,
        search: payload.search,
        filters: payload.filters,
        employees: payload.employees,
        diesel_record_ids: payload.diesel_record_ids,
        diesel_records_detail: payload.diesel_records_detail,
      };
    case FETCH_REQUEST_STATUS_FAILED:
      return {...state, isFetching: false};

    case FETCH_TRIP_ADVANCE_HISTORY:
      return {...state, history: []};
    case FETCH_TRIP_ADVANCE_HISTORY_COMPLETED:
      return {...state, history: payload.history};
    case FETCH_TRIP_ADVANCE_HISTORY_FAILED:
      return {...state, history: []};

    case FETCH_TRIP_CHARGES_BY_CODE:
      return {...state, charges: []};
    case FETCH_TRIP_CHARGES_BY_CODE_COMPLETED:
      return {...state, charges: payload.charges};
    case FETCH_TRIP_CHARGES_BY_CODE_FAILED:
      return {...state, charges: []};

    case FETCH_PREV_TRIP_CHARGES:
      return {...state, prevCharges: {}};
    case FETCH_PREV_TRIP_CHARGES_COMPLETED:
      return {...state, prevCharges: payload.prevCharges};
    case FETCH_PREV_TRIP_CHARGES_FAILED:
      return {...state, prevCharges: {}};

    default:
      return state;
  }
};
