import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Revenue} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  isLoading: false,
  hasError: false,
  error: '',
  customerTripIds: [],
  customer_trips: {},
  customerTripsCount: 0,
  resetPage: false,
  isRequesting: false,
};

const FETCH_REVENUE_ADDED_TRIPS = 'FETCH_REVENUE_ADDED_TRIPS';
const FETCH_REVENUE_ADDED_TRIPS_COMPLETED =
  'FETCH_REVENUE_ADDED_TRIPS_COMPLETED';
const FETCH_REVENUE_ADDED_TRIPS_FAILED = 'FETCH_REVENUE_ADDED_TRIPS_FAILED';

const SAVE_REVENUE_ADDED_TRIP = 'SAVE_REVENUE_ADDED_TRIP';
const SAVE_REVENUE_ADDED_TRIP_COMPLETED = 'SAVE_REVENUE_ADDED_TRIP_COMPLETED';
const SAVE_REVENUE_ADDED_TRIP_FAILED = 'SAVE_REVENUE_ADDED_TRIP_FAILED';

const REVENUE_SUBMIT_BILLING = 'REVENUE_SUBMIT_BILLING';
const REVENUE_SUBMIT_BILLING_COMPLETED = 'REVENUE_SUBMIT_BILLING_COMPLETED';
const REVENUE_SUBMIT_BILLING_FAILED = 'REVENUE_SUBMIT_BILLING_FAILED';

const RESET_PAGE = 'RESET_PAGE';
const APPLY_REVENUE_FILTER = 'APPLY_REVENUE_FILTER';
const CLEAR_REVENUE_FILTER = 'CLEAR_REVENUE_FILTER';

export const fetchRevenueAddedTrips = (search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_REVENUE_ADDED_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRevenueAddedTripsURL(search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: FETCH_REVENUE_ADDED_TRIPS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_REVENUE_ADDED_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_REVENUE_ADDED_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const saveRevenueAddedTrip = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_REVENUE_ADDED_TRIP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.saveRevenueAddedTripURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: SAVE_REVENUE_ADDED_TRIP_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SAVE_REVENUE_ADDED_TRIP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_REVENUE_ADDED_TRIP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const revenueSubmitBilling = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: REVENUE_SUBMIT_BILLING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.revenueSubmitBillingURL(),
        data: requestData,
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: REVENUE_SUBMIT_BILLING_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: REVENUE_SUBMIT_BILLING_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: REVENUE_SUBMIT_BILLING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const applyRevenueFilter = (filter = {}) => {
  return (dispatch) => {
    dispatch({
      type: APPLY_REVENUE_FILTER,
      payload: filter,
    });
  };
};

export const clearRevenueFilter = (filter = {}) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REVENUE_FILTER,
    });
  };
};

const setUpdatedRevenueAddedTrip = (state, payload = {}) => {
  const {customer_trips} = payload;
  if (customer_trips) {
    const oldRoutes = {...state.customer_trips};
    const newRoutes = {...customer_trips};
    const updatedState = {...oldRoutes, ...newRoutes};
    console.log(
      'merged reveneue added trips',
      updatedState,
      oldRoutes,
      newRoutes,
    );
    return {...state, isLoading: false, customer_trips: updatedState};
  } else return state;
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case SAVE_REVENUE_ADDED_TRIP:
      return {...state, isLoading: true};
    case SAVE_REVENUE_ADDED_TRIP_COMPLETED:
      return setUpdatedRevenueAddedTrip(state, payload);
    case SAVE_REVENUE_ADDED_TRIP_FAILED:
      return {...state, isLoading: false, error: payload?.error};
    case FETCH_REVENUE_ADDED_TRIPS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_REVENUE_ADDED_TRIPS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        customerTripIds: payload?.trip_list,
        customer_trips: payload?.trips,
        customerTripsCount: payload?.count,
        search: payload.query_string,
        filter: {
          ...payload?.filters,
        },
      };
    case FETCH_REVENUE_ADDED_TRIPS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        customerTripIds: [],
        customer_trips: {},
      };
    case REVENUE_SUBMIT_BILLING:
      return {...state, isRequesting: true};
    case REVENUE_SUBMIT_BILLING_COMPLETED:
      return {...state, isRequesting: false};
    case REVENUE_SUBMIT_BILLING_FAILED:
      return {...state, isRequesting: false};
    case APPLY_REVENUE_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case CLEAR_REVENUE_FILTER:
      return {
        ...state,
        filter: {},
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
