import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Revenue} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  costData: null,
  countObj: {},
  routeMarginData: null,
  forwardRouteData: null,
  returnRouteData: null,
  uploadingRouteMargin: false,
  uploadingCost: false,
  uploadingReturnRoute: false,
};

export const FETCH_RATE_MATRIX_ROUTE_MARGIN = 'FETCH_RATE_MATRIX_ROUTE_MARGIN';
export const FETCH_RATE_MATRIX_ROUTE_MARGIN_COMPLETED =
  'FETCH_RATE_MATRIX_ROUTE_MARGIN_COMPLETED';
export const FETCH_RATE_MATRIX_ROUTE_MARGIN_FAILED =
  'FETCH_RATE_MATRIX_ROUTE_MARGIN_FAILED';

export const FETCH_RATE_MATRIX_COST = 'FETCH_RATE_MATRIX_COST';
export const FETCH_RATE_MATRIX_COST_COMPLETED =
  'FETCH_RATE_MATRIX_COST_COMPLETED';
export const FETCH_RATE_MATRIX_COST_FAILED = 'FETCH_RATE_MATRIX_COST_FAILED';

export const FETCH_RATE_MATRIX_RETURN_ROUTE = 'FETCH_RATE_MATRIX_RETURN_ROUTE';
export const FETCH_RATE_MATRIX_RETURN_ROUTE_COMPLETED =
  'FETCH_RATE_MATRIX_RETURN_ROUTE_COMPLETED';
export const FETCH_RATE_MATRIX_RETURN_ROUTE_FAILED =
  'FETCH_RATE_MATRIX_RETURN_ROUTE_FAILED';

export const FETCH_RATE_MATRIX_FORWARD_ROUTE =
  'FETCH_RATE_MATRIX_FORWARD_ROUTE';
export const FETCH_RATE_MATRIX_FORWARD_ROUTE_COMPLETED =
  'FETCH_RATE_MATRIX_FORWARD_ROUTE_COMPLETED';
export const FETCH_RATE_MATRIX_FORWARD_ROUTE_FAILED =
  'FETCH_RATE_MATRIX_FORWARD_ROUTE_FAILED';

const UPLOAD_ROUTE_MARGIN = 'UPLOAD_ROUTE_MARGIN';
const UPLOAD_ROUTE_MARGIN_COMPLETED = 'UPLOAD_ROUTE_MARGIN_COMPLETED';
const UPLOAD_ROUTE_MARGIN_FAILED = 'UPLOAD_ROUTE_MARGIN_FAILED';

const UPLOAD_RETURN_ROUTE = 'UPLOAD_RETURN_ROUTE';
const UPLOAD_RETURN_ROUTE_COMPLETED = 'UPLOAD_RETURN_ROUTE_COMPLETED';
const UPLOAD_RETURN_ROUTE_FAILED = 'UPLOAD_RETURN_ROUTE_FAILED';

const UPLOAD_COST = 'UPLOAD_COST';
const UPLOAD_COST_COMPLETED = 'UPLOAD_COST_COMPLETED';
const UPLOAD_COST_FAILED = 'UPLOAD_COST_FAILED';

const FILTER_RATE_MATRIX_DETAILS_FALSE = 'FILTER_RATE_MATRIX_DETAILS_FALSE';
const FILTER_RATE_MATRIX_DETAILS_TRUE = 'FILTER_RATE_MATRIX_DETAILS_TRUE';
const SET_CURRENT_RATE_MATRIX_DATA = 'SET_CURRENT_RATE_MATRIX_DATA';

const RESET_PAGE = 'RESET_PAGE';

export const filteredData = (searchInput = '') => (dispatch) => {
  console.log('filter rate matrix called');
  if (!searchInput) {
    dispatch({
      type: FILTER_RATE_MATRIX_DETAILS_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_RATE_MATRIX_DETAILS_TRUE,
    payload: searchInput,
  });
};

export const currentTableData = (tableData = []) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_RATE_MATRIX_DATA,
    payload: tableData,
  });
};

export const resetPage = () => {
  return async (dispatch) => {
    dispatch({type: RESET_PAGE});
  };
};

export const fetchRateMatrixCost = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_RATE_MATRIX_COST});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRateMatrixCostURL(),
      });
      console.log('response --FETCH_RATE_MATRIX_COST--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_RATE_MATRIX_COST_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_RATE_MATRIX_COST_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_RATE_MATRIX_COST_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchRateMatrixReturnRoute = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_RATE_MATRIX_RETURN_ROUTE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRateMatrixReturnRouteURL(),
      });
      console.log('response --FETCH_RATE_MATRIX_RETURN_ROUTE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_RATE_MATRIX_RETURN_ROUTE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_RATE_MATRIX_RETURN_ROUTE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_RATE_MATRIX_RETURN_ROUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchRateMatrixRouteMargin = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_RATE_MATRIX_ROUTE_MARGIN});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRateMatrixRouteMarginURL(),
      });
      console.log('response --FETCH_RATE_MATRIX_ROUTE_MARGIN--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_RATE_MATRIX_ROUTE_MARGIN_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_RATE_MATRIX_ROUTE_MARGIN_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_RATE_MATRIX_ROUTE_MARGIN_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchRateMatrixForwardRoute = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_RATE_MATRIX_FORWARD_ROUTE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.fetchRateMatrixForwardRouteURL(),
      });
      console.log('response --FETCH_RATE_MATRIX_FORWARD_ROUTE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_RATE_MATRIX_FORWARD_ROUTE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_RATE_MATRIX_FORWARD_ROUTE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_RATE_MATRIX_FORWARD_ROUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const uploadRouteMargin = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_ROUTE_MARGIN});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.uploadRouteMarginURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: UPLOAD_ROUTE_MARGIN_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: UPLOAD_ROUTE_MARGIN_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_ROUTE_MARGIN_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const uploadReturnRoute = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_RETURN_ROUTE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.uploadReturnRouteURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: UPLOAD_RETURN_ROUTE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: UPLOAD_RETURN_ROUTE_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_RETURN_ROUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const uploadCost = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_COST});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.uploadCostURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: UPLOAD_COST_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: UPLOAD_COST_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: UPLOAD_COST_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

const handleRouteMarginUpload = (state, payload) => {
  const isError = payload.missing_trips.is_error;
  let missingTrips = undefined;
  if (isError) {
    missingTrips = payload.missing_trips;
  }
  return {...state, uploadingRouteMargin: false, missingTrips};
};

const handleReturnRouteUpload = (state, payload) => {
  const isError = payload.missing_trips.is_error;
  let missingTrips = undefined;
  if (isError) {
    missingTrips = payload.missing_trips;
  }
  return {...state, uploadingReturnRoute: false, missingTrips};
};

const handleCostUpload = (state, payload) => {
  const isError = payload.missing_trips.is_error;
  let missingTrips = undefined;
  if (isError) {
    missingTrips = payload.missing_trips;
  }
  return {...state, uploadingCost: false, missingTrips};
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_RATE_MATRIX_COST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_RATE_MATRIX_COST_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        costData: payload,
        countObj: payload?.count,
      };
    case FETCH_RATE_MATRIX_COST_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        costData: null,
      };
    case FETCH_RATE_MATRIX_ROUTE_MARGIN:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_RATE_MATRIX_ROUTE_MARGIN_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        routeMarginData: payload,
        countObj: payload?.count,
      };
    case FETCH_RATE_MATRIX_ROUTE_MARGIN_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',
        error: payload.error,
        routeMarginData: null,
      };
    case FETCH_RATE_MATRIX_FORWARD_ROUTE:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_RATE_MATRIX_FORWARD_ROUTE_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        forwardRouteData: payload,
        countObj: payload?.count,
      };
    case FETCH_RATE_MATRIX_FORWARD_ROUTE_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',
        error: payload.error,
        forwardRouteData: null,
      };
    case FETCH_RATE_MATRIX_RETURN_ROUTE:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_RATE_MATRIX_RETURN_ROUTE_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        returnRouteData: payload,
        countObj: payload?.count,
      };
    case FETCH_RATE_MATRIX_RETURN_ROUTE_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',
        error: payload.error,
        returnRouteData: null,
      };
    case SET_CURRENT_RATE_MATRIX_DATA:
      return {
        ...state,
        currentTableData: payload,
      };
    case FILTER_RATE_MATRIX_DETAILS_FALSE:
      return {
        ...state,
        searching: '',
      };
    case FILTER_RATE_MATRIX_DETAILS_TRUE:
      return {
        ...state,
        searching: payload,
      };
    case UPLOAD_ROUTE_MARGIN:
      return {...state, uploadingRouteMargin: true};
    case UPLOAD_ROUTE_MARGIN_COMPLETED:
      return handleRouteMarginUpload(state, payload);
    case UPLOAD_ROUTE_MARGIN_FAILED:
      return {...state, uploadingRouteMargin: false};
    case UPLOAD_RETURN_ROUTE:
      return {...state, uploadingReturnRoute: true};
    case UPLOAD_RETURN_ROUTE_COMPLETED:
      return handleReturnRouteUpload(state, payload);
    case UPLOAD_RETURN_ROUTE_FAILED:
      return {...state, uploadingReturnRoute: false};
    case UPLOAD_COST:
      return {...state, uploadingCost: true};
    case UPLOAD_COST_COMPLETED:
      return handleCostUpload(state, payload);
    case UPLOAD_COST_FAILED:
      return {...state, uploadingCost: false};
    case RESET_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
