import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Attendance, Records} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  selectedDriverId: null,
  selectedDate: null,
  fetchingAttendance: false,
  attendance: {},
  isDownloading: false,
};

const FETCH_DRIVER_LIST = 'FETCH_DRIVER_LIST';
const FETCH_DRIVER_LIST_COMPLETED = 'FETCH_DRIVER_LIST_COMPLETED';
const FETCH_DRIVER_LIST_FAILED = 'FETCH_DRIVER_LIST_FAILED';

const FETCH_ATTENDANCE = 'FETCH_ATTENDANCE';
const FETCH_ATTENDANCE_COMPLETED = 'FETCH_ATTENDANCE_COMPLETED';
const FETCH_ATTENDANCE_FAILED = 'FETCH_ATTENDANCE_FAILED';

const MARK_ATTENDANCE = 'MARK_ATTENDANCE';
const MARK_ATTENDANCE_COMPLETED = 'MARK_ATTENDANCE_COMPLETED';
const MARK_ATTENDANCE_FAILED = 'MARK_ATTENDANCE_FAILED';

const SELECT_DRIVER = 'SELECT_DRIVER';
const SELECT_DATE = 'SELECT_DATE';

const DOWNLOAD_MARK_ATTENDANCE_REPORT = 'DOWNLOAD_MARK_ATTENDANCE_REPORT';
const DOWNLOAD_MARK_ATTENDANCE_REPORT_COMPLETED =
  'DOWNLOAD_MARK_ATTENDANCE_REPORT_COMPLETED';
const DOWNLOAD_MARK_ATTENDANCE_REPORT_FAILED =
  'DOWNLOAD_MARK_ATTENDANCE_REPORT_FAILED';

export const selectDriver = (selectedDriverId) => {
  return async (dispatch) => {
    dispatch({type: SELECT_DRIVER, payload: {selectedDriverId}});
  };
};

export const selectDate = (selectedDate) => {
  return async (dispatch) => {
    dispatch({type: SELECT_DATE, payload: {selectedDate}});
  };
};

export const fetchDriverList = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_LIST});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Attendance.fetchAllZastDriverListURL(search),
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: FETCH_DRIVER_LIST_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: FETCH_DRIVER_LIST_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: FETCH_DRIVER_LIST_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const fetchAttendanceById = (driverId, date) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ATTENDANCE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Attendance.fetchAttendanceByIdURL(driverId, date),
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: FETCH_ATTENDANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: FETCH_ATTENDANCE_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: FETCH_ATTENDANCE_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const markAttendance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_ATTENDANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Attendance.markAttendanceURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: MARK_ATTENDANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: MARK_ATTENDANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_ATTENDANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setAttendance = (state, payload) => {
  return {...state, fetchingAttendance: false, attendance: {...payload}};
};

const updateAttendance = (state, payload) => {
  return {...state, isRequesting: false, attendance: {...payload}};
};
export const downloadDriverAttendance = (requestData, params) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_MARK_ATTENDANCE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Attendance.fetchDownloadMarkAttendanceURL(params),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_MARK_ATTENDANCE_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_MARK_ATTENDANCE_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_MARK_ATTENDANCE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_DRIVER_LIST:
      return {...state, isFetching: true, selectedDriverId: null};
    case FETCH_DRIVER_LIST_COMPLETED:
      return {
        ...state,
        isFetching: false,
        employees: payload.employees,
        employeeIds: payload.employee_ids,
        vehicles: payload.vehicles,
        vehicleIds: payload.vehicle_ids,
        driverIds: payload?.driver_list,
        search: payload.query_string,
        filter: {
          ...payload.filters,
        },
      };
    case FETCH_DRIVER_LIST_FAILED:
      return {
        ...state,
        isFetching: false,
        driverIds: [],
        employees: {},
        employeeIds: [],
      };

    case FETCH_ATTENDANCE:
      return {...state, fetchingAttendance: true};
    case FETCH_ATTENDANCE_COMPLETED:
      return setAttendance(state, payload);
    case FETCH_ATTENDANCE_FAILED:
      return {...state, fetchingAttendance: false};

    case MARK_ATTENDANCE:
      return {...state, isRequesting: true};
    case MARK_ATTENDANCE_COMPLETED:
      return updateAttendance(state, payload);
    case MARK_ATTENDANCE_FAILED:
      return {...state, isRequesting: false};

    case SELECT_DRIVER:
      return {...state, selectedDriverId: payload.selectedDriverId};
    case SELECT_DATE:
      return {...state, selectedDate: payload.selectedDate};
    case DOWNLOAD_MARK_ATTENDANCE_REPORT:
      return {
        ...state,
        error: '',
        isDownloading: true,
      };
    case DOWNLOAD_MARK_ATTENDANCE_REPORT_COMPLETED:
      return {
        ...state,
        isDownloading: false,
      };
    case DOWNLOAD_MARK_ATTENDANCE_REPORT_FAILED:
      return {
        ...state,
        isDownloading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};
