import {
  FETCH_INITIAL_DATA,
  FETCH_INITIAL_DATA_COMPLETED,
  FETCH_INITIAL_DATA_FAILED,
} from '../../auth';

const INITIAL_STATE = {
  isFetching: true,
};

export default (state = INITIAL_STATE, action) => {
  const {type} = action || {};
  switch (type) {
    case FETCH_INITIAL_DATA:
      return {isFetching: true};
    case FETCH_INITIAL_DATA_COMPLETED:
    case FETCH_INITIAL_DATA_FAILED:
      return {isFetching: false};
    default:
      return state;
  }
};
