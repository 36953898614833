import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {OwnVehicles} from '../../../helpers/Urls';

const intialState = {
  isLoading: false,
  isFetching: false,
  maintenance_record: {},
  maintenance_ids: [],
};

export const SAVE_MAINTENANCE_REPORT = 'SAVE_MAINTENANCE_REPORT';
export const SAVE_MAINTENANCE_REPORT_COMPLETED =
  'SAVE_MAINTENANCE_REPORT_COMPLETED';
export const SAVE_MAINTENANCE_REPORT_FAILED = 'SAVE_MAINTENANCE_REPORT_FAILED';

export const GET_REPAIR_REPORT = 'GET_REPAIR_REPORT';
export const GET_REPAIR_REPORT_COMPLETED = 'GET_REPAIR_REPORT_COMPLETED';
export const GET_REPAIR_REPORT_FAILED = 'GET_REPAIR_REPORT_FAILED';

export const saveMaintenanceReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_MAINTENANCE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: OwnVehicles.saveMaintenanceReportURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SAVE_MAINTENANCE_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SAVE_MAINTENANCE_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_MAINTENANCE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getRepairMaintenanceReport = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_REPAIR_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: OwnVehicles.getRepairMaintenanceReportURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_REPAIR_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_REPAIR_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_REPAIR_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case SAVE_MAINTENANCE_REPORT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: '',
      };
    case SAVE_MAINTENANCE_REPORT_COMPLETED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        error: '',
        maintenance_record: payload?.maintenance_record || {},
        maintenance_ids: payload?.maintenance_ids || [],
      };
    case SAVE_MAINTENANCE_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: payload.error,
      };
    case GET_REPAIR_REPORT:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case GET_REPAIR_REPORT_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        maintenance_record: payload?.maintenance_record || {},
        maintenance_ids: payload?.maintenance_ids || [],
      };
    case GET_REPAIR_REPORT_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        maintenance_record: {},
        maintenance_ids: [],
      };
    default:
      return state;
  }
};
